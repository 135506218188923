// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

// todo : tester en staging pck j'avais pas le callback en local
export const billingApi = createApi({
	reducerPath: 'billingApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['billing-entity'],
	endpoints: (build) => ({
		getBillingEntity: build.query({
			query: () => `/api/billing/entity`,
			providesTags: ['billing-entity']
		}),
		getBillingEntityFromStripe: build.query({
			query: () => `/api/billing/stripe`,
			providesTags: ['billing-entity']
		}),
		getPlan: build.query({
			query: () => `/api/billing/plans`
		}),
		initBillingSubscription: build.mutation({
			query: (body: { priceId: string; period: string }) => ({
				url: `/api/billing/init`,
				method: 'POST',
				body
			})
		}),
		initStripePortal: build.mutation({
			query: (body) => ({
				url: `/api/billing/portal`,
				method: 'POST',
				body
			})
		}),
		initFreeTrial: build.mutation({
			query: (body: { organizationId: number }) => ({
				url: `/api/subscription/trial`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['billing-entity']
		}),
		createBillingEntity: build.mutation({
			query: (body: {
				email: string;
				firstname?: string;
				lastname?: string;
				name?: string;
				line1?: string;
				line2?: string;
				postalCode?: string;
				city?: string;
				country?: string;
				vatNumber?: string;
			}) => ({
				url: `/api/billing/entity`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['billing-entity']
		}),
		changeBillingPlan: build.mutation({
			query: (body: { plan: string; period: string }) => ({
				url: `/api/billing/change/subscription`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['billing-entity']
		}),
		changeBillingPeriod: build.mutation({
			query: (body: { plan: string; period: string }) => ({
				url: `/api/billing/change/period`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['billing-entity']
		})
	})
});

export const {
	useChangeBillingPeriodMutation,
	useChangeBillingPlanMutation,
	useCreateBillingEntityMutation,
	useGetBillingEntityFromStripeQuery,
	useGetBillingEntityQuery,
	useInitBillingSubscriptionMutation,
	useInitFreeTrialMutation,
	useInitStripePortalMutation,
	useGetPlanQuery
} = billingApi;
