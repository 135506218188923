import React, { useCallback, useEffect, useRef, useState } from 'react';

// Material
import { Box, Link, TextField, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Externals
import { confirmPasswordReset, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Formik } from 'formik';
import * as yup from 'yup';
import { t, Trans } from '@lingui/macro';
import { FirebaseError } from 'firebase/app';
import toast from 'react-hot-toast';
import { usePostUserProfileExistsMutation } from 'components/legacy/services/userProfiles';

type Step = 'form' | 'code-sent' | 'reset-form';

interface ResetPasswordStepProps {
	onGoBack: () => void;
	onFinished: () => void;
	initialEmail: string;
}

const ResetPasswordStep = ({ onGoBack, initialEmail, onFinished }: ResetPasswordStepProps) => {
	const auth = getAuth();
	const theme = useTheme();

	const params = new URLSearchParams(window.location.search); // id=123
	const code = params.get('code'); // 123
	const [isUserExist] = usePostUserProfileExistsMutation();

	const itemsRef = useRef([]);

	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(0, 6);
	}, []);

	const initialStep: Step = code ? 'reset-form' : 'form';

	const [step, setStep] = useState<Step>(initialStep);
	const [error, setError] = useState<string>('');

	const goBackCallback = useCallback(() => {
		setStep('form');
		onGoBack();
	}, [onGoBack]);

	if (step === 'form') {
		return (
			<Formik
				initialValues={{
					email: initialEmail
				}}
				onSubmit={async (values) => {
					auth.languageCode = 'fr';
					const res = await isUserExist({ email: values.email }).unwrap();
					if (!res?.data?.userExists) {
						toast.error(`Votre adresse e-mail professionelle n'est pas valide.`);
					} else {
						await sendPasswordResetEmail(auth, values.email, {
							url: `${window.location.origin}/authentication/onboarding?email=${values.email}`
						});
						setStep('code-sent');
					}
				}}
				validateOnMount={true}
				validationSchema={yup.object().shape({
					email: yup
						.string()
						.email(t`Votre adresse e-mail professionelle n'est pas valide.`)
						.required(t`Votre adresse e-mail professionelle est nécessaire.`)
				})}
			>
				{({
					handleBlur,
					handleChange,
					handleReset,
					handleSubmit,
					isValid,
					isSubmitting,
					errors,
					touched,
					values,
					submitForm
				}): JSX.Element => (
					<form noValidate={true} onReset={handleReset} onSubmit={handleSubmit}>
						<Box
							sx={{
								p: 1
							}}
						>
							<Typography align="center" color="text" variant="body1" sx={{ fontSize: '13px' }}>
								<Trans>
									{t`Vous avez oublié votre mot de passe ? Confirmez votre adresse email pour recevoir un code de
                  confirmation par email et réinitialiser votre mot de passe`}
								</Trans>
							</Typography>
							<TextField
								error={Boolean(touched.email && errors.email)}
								fullWidth={true}
								helperText={touched.email && errors.email}
								label={t`Adresse e-mail`}
								margin="normal"
								name="email"
								onBlur={handleBlur}
								onChange={handleChange}
								type="email"
								value={values.email}
								InputProps={{
									sx: { borderRadius: '8px' }
								}}
								InputLabelProps={{ shrink: true }}
								variant="standard"
								sx={{
									input: {
										backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#DCDCDC',
										color: theme.palette.mode === 'dark' ? '#FFF' : '#000'
									},
									// Couleur de la ligne/bordure lorsqu'on focus l'input
									'& .MuiInput-underline:before': {
										borderBottomColor: '#727BFE' // Avant de focus (ligne non active)
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: '#98BAEC' // Quand l'input est en focus
									},
									// Couleur du label quand l'input est actif (focus ou avec du texte)
									'& .MuiInputLabel-root.Mui-focused': {
										color: '#727BFE'
									}
								}}
							/>
							{error && (
								<Box sx={{ pt: 2 }}>
									<Typography sx={{ color: (theme) => theme.palette.warning.main }}>{error}</Typography>
								</Box>
							)}
							<Box sx={{ pt: 2 }}>
								<LoadingButton
									color="primary"
									disabled={!isValid || isSubmitting}
									fullWidth={true}
									loading={isSubmitting}
									onClick={() => submitForm()}
									type="submit"
									variant="contained"
								>
									<Trans id={undefined}>{t`Réinitialiser mon mot de passe`}</Trans>
								</LoadingButton>
								<Typography align="right" color="black" sx={{ mt: 2 }} variant="subtitle1">
									<Link
										sx={{ cursor: 'pointer' }}
										color="primary"
										onClick={goBackCallback}
										underline="hover"
										variant="subtitle1"
									>
										<Trans>{t`Je connais mon mot de passe`}</Trans>
									</Link>
								</Typography>
							</Box>
						</Box>
					</form>
				)}
			</Formik>
		);
	}

	if (step === 'code-sent') {
		return (
			<Box
				sx={{
					p: 4
				}}
			>
				<Typography align="center" color="textPrimary" variant="body2">
					<Trans>
						{t`Vous allez recevoir un email contenant des instructions pour réinitialiser votre mot de passe. Veuillez
            suivre les instructions dans cet email pour continuer la procédure.`}
					</Trans>
				</Typography>
			</Box>
		);
	}

	if (step === 'reset-form') {
		return (
			<Formik
				initialValues={{
					code: code,
					password: ''
				}}
				onSubmit={async (values) => {
					try {
						await confirmPasswordReset(auth, values.code, values.password);
						onFinished();
					} catch (e) {
						console.error(e);
						const code = (e as FirebaseError).code;
						if (code === 'auth/invalid-action-code') {
							setError(t`Le code n'est plus valide`);
						} else if (code === 'auth/weak-password') {
							setError(t`Le code doit faire au minimum 6 caractères`);
						} else if (code === 'auth/user-not-found') {
							setError(t`L'utilisateur n'existe pas.`);
						}
					}
				}}
				validateOnMount={true}
				validationSchema={yup.object().shape({
					password: yup.string().required(t`Votre nouveau mot de passe est nécessaire.`)
				})}
			>
				{({
					handleBlur,
					handleChange,
					handleReset,
					handleSubmit,
					isValid,
					isSubmitting,
					errors,
					touched,
					values,
					submitForm
				}): JSX.Element => (
					<form noValidate={true} onReset={handleReset} onSubmit={handleSubmit}>
						<Box
							sx={{
								p: 4
							}}
						>
							<TextField
								disabled={true}
								error={Boolean(touched.code && errors.code)}
								fullWidth={true}
								helperText={touched.code && errors.code}
								label={t`Code de réinitialisation`}
								margin="normal"
								name="code"
								onBlur={handleBlur}
								onChange={handleChange}
								onKeyPress={(e) => {
									if (e.key === 'Enter') submitForm();
								}}
								type="text"
								value={values.code}
							/>
							<TextField
								error={Boolean(touched.password && errors.password)}
								fullWidth={true}
								helperText={touched.password && errors.password}
								label={t`Nouveau mot de passe`}
								margin="normal"
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								onKeyPress={(e) => {
									if (e.key === 'Enter') submitForm();
								}}
								type="password"
								value={values.password}
							/>
							{error && (
								<Box sx={{ pt: 2 }}>
									<Typography sx={{ color: (theme) => theme.palette.warning.main }}>{error}</Typography>
								</Box>
							)}
							<Box sx={{ pt: 2 }}>
								<LoadingButton
									color="primary"
									disabled={!isValid || isSubmitting}
									fullWidth={true}
									loading={isSubmitting}
									onClick={() => submitForm()}
									type="submit"
									variant="contained"
								>
									<Trans>{t`Modifier le mot de passe`}</Trans>
								</LoadingButton>
								<Typography align="right" color="black" sx={{ mt: 2 }} variant="subtitle1">
									<Link
										sx={{ cursor: 'pointer' }}
										color="gray"
										onClick={goBackCallback}
										underline="hover"
										variant="subtitle1"
									>
										<Trans>{t`Je connais mon mot de passe`}</Trans>
									</Link>
								</Typography>
							</Box>
						</Box>
					</form>
				)}
			</Formik>
		);
	}
};

export default ResetPasswordStep;
