// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

interface IResponse {
	data: any;
	errors: any;
}
interface hasPermissionResponse extends IResponse {
	data: { hasPermission: boolean };
}

export const organizationMemberApi = createApi({
	reducerPath: 'organizationMemberApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: [
		'my-pending-invitations',
		'pending-invitations',
		'role',
		'list-members',
		'dashboard-settings',
		'report-settings',
		'dashboard-actif'
	],
	endpoints: (build) => ({
		getPendingInvitations: build.query({
			query: ({ organizationId }) => `/api/organizations/members/invitations/list/${organizationId}`,
			providesTags: ['pending-invitations'],
			extraOptions: {}
		}),
		getHasPermission: build.query<hasPermissionResponse, { permissionKeys: string[]; allMatch: boolean } | string[]>({
			query: (params) => {
				const isDirectArray = Array.isArray(params); // Vérifier si `params` est un tableau
				const permissionKeys = isDirectArray ? params : params.permissionKeys;
				const allMatch = isDirectArray ? false : params.allMatch ?? false;

				const queryParams = permissionKeys.map((key) => `permissionKeys=${encodeURIComponent(key)}`).join('&');
				return `/api/organizations/members/hasPermission?${queryParams}&allMatch=${allMatch}`;
			},
			providesTags: ['role']
		}),
		getMyPendingInvitations: build.query({
			query: () => `/api/organizations/members/invitations/self/list`,
			providesTags: ['my-pending-invitations']
		}),

		getOrganizationMembers: build.query({
			query: ({ organizationId }) => `/api/organizations/members/list/${organizationId}`,
			providesTags: ['list-members']
		}),
		getOrganizationMember: build.query({
			query: ({ organizationId, userId }) => `/api/organizations/members/member/${organizationId}/${userId}`,
			providesTags: ['role']
		}),
		getMyMembershipsOwned: build.query({
			query: () => `/api/organizations/members/memberships-owned/list`
		}),
		checkInvitation: build.query({
			query: ({ invitationId }) => `/api/invitation/check/${invitationId}`
		}),
		KPIDashboardSettingsActif: build.mutation({
			query: (body: { dashboardList: object }) => ({
				url: `/api/organizations/members/kpi/dashboard`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['dashboard-settings']
		}),
		KPIReportSettingsActif: build.mutation({
			query: (body: { reportList: object }) => ({
				url: `/api/organizations/members/kpi/report`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['report-settings']
		}),
		sendInvitation: build.mutation({
			query: (body: { organizationId: number; email: string; roleKey: string }) => ({
				url: `/api/organizations/members/invite`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['pending-invitations']
		}),
		//TODO : pk un POST ?
		removeMember: build.mutation({
			query: (body: { userId: string; organizationId: number }) => ({
				url: `/api/organizations/members/remove`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['list-members']
		}),
		declineInvitation: build.mutation({
			query: (body: { invitationId: string }) => ({
				url: `/api/organizations/members/invitation/decline`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['my-pending-invitations']
		}),
		cancelInvitation: build.mutation({
			query: (body: { invitationId: string }) => ({
				url: `/api/organizations/members/invitation/cancel`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['pending-invitations']
		}),
		acceptInvitation: build.mutation({
			query: (body: { invitationId: string; userId: string }) => ({
				url: `/api/organizations/members/invitation/accept`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['my-pending-invitations']
		}),
		getMemberSettingsDashboard: build.query({
			query: () => `/api/organizations/members/dashboard`,
			providesTags: ['dashboard-settings']
		}),
		getMemberSettingsReport: build.query({
			query: () => `/api/organizations/members/report`,
			providesTags: ['report-settings']
		})
	})
});

export const {
	useAcceptInvitationMutation,
	useCancelInvitationMutation,
	useCheckInvitationQuery,
	useDeclineInvitationMutation,
	useGetMyMembershipsOwnedQuery,
	useGetMyPendingInvitationsQuery,
	useGetOrganizationMemberQuery,
	useGetOrganizationMembersQuery,
	useGetPendingInvitationsQuery,
	useSendInvitationMutation,
	useRemoveMemberMutation,
	useGetMemberSettingsDashboardQuery,
	useKPIDashboardSettingsActifMutation,
	useGetMemberSettingsReportQuery,
	useKPIReportSettingsActifMutation,
	useGetHasPermissionQuery
} = organizationMemberApi;
